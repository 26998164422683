import React, { Component } from 'react';
import MopedCard from '../components/MopedCard';
import getMopeds from '../firebase/getMopeds';
import * as Icons from 'react-feather';
import Moped from '../models/Moped';
import Checkbox from '../components/checkbox';
import arrayRemove from '../utils/arrayRemove';
import { MopedsSiteData } from '../firebase/getMopedsPageData';
import IconButton from '../components/IconButton';

import '../scss/mopeds.scss';
import '../scss/mopeds-responsive.scss';
import SEO from '../components/seo';

export function onlyUnique<T>(value: T, index: number, self: T[]) {
    return self.indexOf(value) === index;
}

export function formatSearch(str?: string) {
    return str?.replace(/\s+|-+|_+/g, '')?.toLowerCase()
}

export function allColors(mopeds: Moped[]): string[] {
    let colors: string[] = [];

    for (let i of mopeds
        .filter(m => m.colors?.length)
        .map(m => m.colors.map(c => c.color))) {
        colors.push(...i);
    }

    return colors.filter(onlyUnique);
}

export interface MopedsPageProps {
    pageContext: {
        mopeds: Moped[],
        mopedsSiteData: MopedsSiteData,
        description?: string
    }
}

export interface MopedsPageState {
    mopeds: Moped[],
    scrolled: boolean,
    selectedBrands: string[],
    selectedEngineTypes: string[],
    searchQuery?: string,
    showFilters: boolean,
    color: string | 'no-color'
}

export default class MopedsPage extends Component<MopedsPageProps> {

    state: MopedsPageState = {
        mopeds: [],
        scrolled: false,
        selectedBrands: [],
        selectedEngineTypes: [],
        showFilters: false,
        color: 'no-color'
    }

    componentDidMount() {
        this.setMopeds();
    }

    componentDidUpdate() {
        this.checkScroll();
    }

    componentWillUnmount() {
        this.clearScrolled();
    }

    async setMopeds() {
        const mopeds = await getMopeds();
        this.setState({ mopeds });
        this.setScrolled();
    }

    clearScrolled() {
        window.onscroll = null;
        window.onresize = null;
    }

    setScrolled() {
        this.checkScroll();

        window.onresize = () => {
            this.checkScroll();
        }

        window.onscroll = () => {
            this.checkScroll();
        }
    }

    checkScroll() {
        let amountScrolled = Math.max(document.body.scrollTop, document.documentElement.scrollTop, window.pageYOffset);
        let scrollDifference = document.getElementById('content')?.scrollHeight + 85 - amountScrolled;
        let viewport = window.innerHeight;
        let scrollRemaining = scrollDifference - viewport;
        let scrolled = scrollRemaining <= 0;

        if (scrolled != this.state.scrolled) {
            this.setState({
                scrolled
            });
        }
    }

    toggleBrand(brand: string) {
        let { selectedBrands } = this.state;

        if (selectedBrands.includes(brand))
            selectedBrands = arrayRemove(selectedBrands, brand);
        else selectedBrands.push(brand);

        this.setState({ selectedBrands });

        this.checkScroll();
    }

    toggleEngine(engine: string) {
        let { selectedEngineTypes } = this.state;

        if (selectedEngineTypes.includes(engine))
            selectedEngineTypes = arrayRemove(selectedEngineTypes, engine);
        else selectedEngineTypes.push(engine);

        this.setState({ selectedEngineTypes });

        this.checkScroll();
    }

    search(query: string) {
        this.setState({ searchQuery: formatSearch(query) });

        this.checkScroll();
    }

    toggleShowFilters(force?: boolean) {
        const { showFilters } = this.state;
        this.setState({ showFilters: force != undefined && force != null ? force : !showFilters });
    }

    render() {

        let {
            mopeds: stateMopeds,
            scrolled,
            selectedBrands,
            selectedEngineTypes,
            searchQuery,
            showFilters,
            color
        } = this.state;

        console.log(searchQuery);

        let propsMopeds = this.props.pageContext?.mopeds;

        const { mopedsSiteData, description } = this.props.pageContext;

        console.log(mopedsSiteData);

        const featuredBrands = mopedsSiteData?.featuredBrands;
        const engineTypes = mopedsSiteData?.engineTypes;

        let mopeds = stateMopeds || propsMopeds || [];

        for (let moped of mopeds) {
            // Check if moped does not match any filters
            if (selectedBrands && selectedBrands.length && !selectedBrands.includes(moped.brand))
                mopeds = arrayRemove(mopeds, moped);
            else if (selectedEngineTypes && selectedEngineTypes.length && !selectedEngineTypes.includes(moped.cc))
                mopeds = arrayRemove(mopeds, moped);

            // Arrange mopeds based on search
            if (searchQuery) {
                if (formatSearch(moped.displayName).includes(searchQuery)) {
                    mopeds = arrayRemove(mopeds, moped);
                    mopeds.unshift(moped);
                } else if (formatSearch(`${moped.cc}cc`).includes(searchQuery)) {
                    // move to end of list if searched matched engine,
                    // because even if the search matched the engine,
                    // searching for the name of a moped should show up
                    // before a moped that has the engine that matches the
                    // search.
                    mopeds = arrayRemove(mopeds, moped);
                    mopeds.push(moped);
                } else if (!formatSearch(moped.brand).includes(searchQuery))
                    mopeds = arrayRemove(mopeds, moped);
            }
        }

        const noMopedsFound =
            (selectedBrands?.length || selectedEngineTypes?.length || searchQuery) &&
            (stateMopeds?.length || propsMopeds?.length);

        const renderMopeds = (mopeds: Moped[]) => {
            return mopeds.filter(m => {
                if (color !== 'no-color') {
                    return m.colors.map(c => c.color).includes(color);
                } else return true;
            }).map(moped => {
                if (color === 'no-color') {
                    console.log(moped);
                    let newMoped = moped;
                    newMoped.image = propsMopeds.find(m => m.id === moped.id).image;
                    return <MopedCard key={moped.id + '---no-color'} moped={newMoped} />
                } else {
                    let newMoped = moped;
                    newMoped.image = moped.colors.find(c => c.color === color).image;
                    return <MopedCard key={`${newMoped.id}---${color}`} moped={newMoped} />
                }
            })
        }

        return (
            <div className={`mopeds-page${scrolled ? ' scrolled' : ''}`} id="content">
                <SEO title="Mopeds" description={description} />
                <div className='mopeds'>
                    <div className="mopeds-sidebar">
                        <h1 className="title">
                            Mopeds
                            <div className="filters-icon">
                                <IconButton aria-label="Filter" onClick={() => {
                                    this.toggleShowFilters(true);
                                }} icon={
                                    <Icons.Sliders />
                                } />
                            </div>
                        </h1>
                        <label className="moped-search" tabIndex={0}>
                            <Icons.Search size={20} />
                            <input type="text" placeholder="Search" onChange={event => {
                                this.search(event.currentTarget.value);
                            }} />
                        </label>
                        <br className="spacing" />
                        <div className={`mobile-filters-overlay${showFilters ? ' visible' : ''}`} />
                        <div className={`filters${showFilters ? ' visible' : ''}`}>
                            <h2 className="mobile-filters-header">
                                Filter Mopeds
                                <IconButton aria-label="Close" onClick={() => {
                                    this.toggleShowFilters(false);
                                }} icon={
                                    <Icons.X />
                                } />
                            </h2>
                            {
                                featuredBrands && featuredBrands.length && (
                                    <div className="filter">
                                        <h2>Brand</h2>
                                        <div className="selection">
                                            {
                                                featuredBrands.map(brand => {
                                                    return <Checkbox checked={selectedBrands.includes(brand)} onChange={() => {
                                                        this.toggleBrand(brand);
                                                    }} className='select' key={brand} label={brand} />
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            {/* <div className="filter">
                                <h2>Color</h2>
                                <div className="selection-space"></div>
                                <div className="color-selection">
                                    {
                                        allColors(mopeds).map(c => (
                                            <div
                                                className={`color${color === c ? ' active' : ''}`}
                                                onClick={() => {
                                                    if (color === c) {
                                                        this.setState({
                                                            color: 'no-color'
                                                        });

                                                        return;
                                                    }

                                                    this.setState({
                                                        color: c
                                                    });
                                                }}
                                                style={{
                                                    background: c,
                                                    borderColor: c === '#f4f7fb' ?
                                                        'rgba(0, 0, 30, 0.1)' : 'transparent'
                                                }}></div>
                                        ))
                                    }
                                </div>
                            </div> */}
                            {/* {
                                featuredBrands && featuredBrands.length && (
                                    <div className="filter">
                                        <h2>Engine</h2>
                                        <div className="selection">
                                            {
                                                engineTypes.map(engine => {
                                                    return <Checkbox checked={selectedEngineTypes.includes(engine)} onChange={() => {
                                                        this.toggleEngine(engine);
                                                    }} className='select' key={engine} label={`${engine}CC`} />
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            } */}
                        </div>
                    </div>
                    <div className="mopeds-sidebar-push"></div>
                    {
                        mopeds.length ?
                            <div className="moped-cards" key={color}>{
                                renderMopeds(mopeds)
                            }</div>
                            : noMopedsFound ? (
                                <div className="no-mopeds">No mopeds found.</div>
                            ) : propsMopeds && (
                                <div className="moped-cards" key={color}>{
                                    renderMopeds(propsMopeds)
                                }</div>
                            )
                    }
                </div>
            </div>
        )
    }
}